import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"flex-direction": "column",
			"justify-content": "center",
			"margin": "0px 0px 56px 0px",
			"width": "100%",
			"sm-margin": "0px 0px 30px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 16px 0px",
			"color": "--dark",
			"text-align": "center",
			"sm-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"children": <>
				YORUMLAR{"\n\n"}
			</>,
			"font": "--headline2"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--grey",
			"text-align": "center",
			"font": "--lead",
			"children": <>
				Üyelerimizin başarılarıyla gurur duyuyoruz. İşte FitZone'dan bazı ilham verici hikayeler:
			</>
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px",
			"lg-grid-template-columns": "repeat(2, 1fr)",
			"md-grid-template-columns": "1fr"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"padding": "50px 55px 50px 55px",
			"sm-padding": "55px 40px 50px 55px",
			"border-width": "1px",
			"border-style": "solid",
			"border-radius": "24px",
			"border-color": "--color-lightD2",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 35px 0px",
			"color": "--dark",
			"font": "--lead",
			"lg-margin": "0px 0px 50px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"flex": "1 0 auto",
			"children": <>
				“FitZone’a katıldığım günden bu yana 20 kilo verdim ve kendime olan güvenim arttı. Eğitmenler inanılmaz destekleyici ve motive ediciydi”
			</>
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 17px 0px 0px",
			"align-items": "flex-start",
			"flex-direction": "column"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"width": "43px",
			"height": "43px",
			"src": "https://varuntarest.com/img/p1.png",
			"border-radius": "50px",
			"margin": "0px 15px 12px 0px"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"color": "--dark",
			"font": "normal 600 16px/1.5 --fontFamily-googleSourceSansPro",
			"margin": "0px 0px 2px 0px",
			"children": <>
				Asli{"\n\n"}
			</>
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"padding": "50px 55px 50px 55px",
			"sm-padding": "55px 40px 50px 55px",
			"border-width": "1px",
			"border-style": "solid",
			"border-radius": "24px",
			"border-color": "--color-lightD2",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 35px 0px",
			"color": "--dark",
			"font": "--lead",
			"lg-margin": "0px 0px 50px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"flex": "1 0 auto",
			"children": <>
				“Özelleştirilmiş antrenmanlar, sonuçlarım üzerinde büyük bir etki yarattı. FitZone’u, fiziksel sağlığına önem veren herkese şiddetle tavsiye ederim”
			</>
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 17px 0px 0px",
			"align-items": "flex-start",
			"flex-direction": "column"
		}
	},
	"image3": {
		"kind": "Image",
		"props": {
			"width": "43px",
			"height": "43px",
			"src": "https://varuntarest.com/img/p2.png",
			"border-radius": "50px",
			"margin": "0px 15px 12px 0px"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"color": "--dark",
			"font": "normal 600 16px/1.5 --fontFamily-googleSourceSansPro",
			"margin": "0px 0px 2px 0px",
			"children": <>
				Adem{"\n\n"}
			</>
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"padding": "50px 55px 50px 55px",
			"sm-padding": "55px 40px 50px 55px",
			"border-width": "1px",
			"border-style": "solid",
			"border-radius": "24px",
			"border-color": "--color-lightD2",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 35px 0px",
			"color": "--dark",
			"font": "--lead",
			"lg-margin": "0px 0px 50px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"flex": "1 0 auto",
			"children": <>
				“FitZone’daki grup dersleri harika. Harika insanlarla tanıştım ve asla mümkün olduğunu düşünmediğim fitness hedeflerine ulaştım”
			</>
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 17px 0px 0px",
			"align-items": "flex-start",
			"flex-direction": "column"
		}
	},
	"image5": {
		"kind": "Image",
		"props": {
			"width": "43px",
			"height": "43px",
			"src": "https://varuntarest.com/img/p3.png",
			"border-radius": "50px",
			"margin": "0px 15px 12px 0px"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"color": "--dark",
			"font": "normal 600 16px/1.5 --fontFamily-googleSourceSansPro",
			"margin": "0px 0px 2px 0px",
			"children": <>
				Alara{"\n\n"}
			</>
		}
	}
};


const Testimonials = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		<Box {...override("box1")}>
			<Box {...override("box2")}>
				<Text {...override("text2")} />
				<Box {...override("box3")}>
					<Image {...override("image1")} />
					<Box {...override("box4")}>
						<Text {...override("text3")} />
					</Box>
				</Box>
			</Box>
			<Box {...override("box5")}>
				<Text {...override("text4")} />
				<Box {...override("box6")}>
					<Image {...override("image3")} />
					<Box {...override("box7")}>
						<Text {...override("text5")} />
					</Box>
				</Box>
			</Box>
			<Box {...override("box8")}>
				<Text {...override("text6")} />
				<Box {...override("box9")}>
					<Image {...override("image5")} />
					<Box {...override("box10")}>
						<Text {...override("text7")} />
					</Box>
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Testimonials, { ...Section,
	defaultProps,
	overrides
});
export default Testimonials;